<template>
  <div class="d-flex-column align-center main">
    <div class="mw-400 input-underline">
      <el-select placeholder="Select member" v-model="selectVal" class="full-width">
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>

    <!--  TABLE START  -->
    <div class="table-wrapper d-flex">
      <div class="el-table">
        <table>
          <colgroup>
            <col name="col_1" />
            <col name="col_2" />
            <col name="col_3" />
            <col name="col_4" />
            <col name="col_5" />
          </colgroup>

          <thead class="bg-transparent">
            <tr>
              <th colspan="1" rowspan="1" class="col_1 is-leaf">
                <div class="cell">Season</div>
              </th>
              <th colspan="1" rowspan="1" class="col_2 is-leaf">
                <div class="cell">Dispensation Category</div>
              </th>
              <th colspan="1" rowspan="1" class="col_3 is-leaf">
                <div class="cell">Dispensation Rule</div>
              </th>
              <th colspan="1" rowspan="1" class="col_4 is-leaf">
                <div class="cell">Date</div>
              </th>
              <th colspan="1" rowspan="1" class="col_5 is-leaf">
                <div class="cell">Dispensation Status</div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(row, index) in tableData" :key="index" class="el-table__row">
              <td v-for="(col, i) in row" :key="i">
                <div class="cell">{{ col }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mw-400 input-underline">
      <el-select
        id="dispSelector"
        placeholder="Select dispensation"
        v-model="selectDisp"
        class="full-width"
      >
        <el-option
          v-for="option in dispOptions"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </div>
    <div v-if="selectVal" class="align-center d-flex-column">
      <p>
        Rugby league is the game for all. To ensure everyone has their chance to shine, individuals
        can apply to play down under the 18 Month Registration Window. Find out more about
        eligibility requirements
        <a
          href="https://support.playrugbyleague.com/hc/en-us/articles/360001414056-18-Month-Registration-Policy"
          target="_blank"
          >here</a
        >, or if you didn't apply during registration, you can apply now.
      </p>
      <el-button @click="getDispensationForm" type="primary" class="full-width mw-400">
        Apply Here
      </el-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";

import msg from "@/utils/constants/msg";
import { isMinorConsistent } from "@/utils/date/minor";
import endpoints, { endpointParams } from "@/utils/constants/api";
import { typesOfDispensations } from "@/utils/constants/index";

export default {
  name: "dispensations",
  mounted() {
    this.LOADING(true);
    this.$http
      .get(endpoints.getMembers)
      .then((membersResponse) => {
        const participantsActiveRegos = {};
        const dispHistory = {};
        const options = membersResponse.data.data.map(
          ({ _id: value, profile, activeRegos, dispensations }) => {
            participantsActiveRegos[value] = { profile, activeRegos };
            dispHistory[value] = dispensations || [];
            return {
              label: `${profile.firstName} ${profile.lastName}`,
              value,
            };
          }
        );
        this.$set(this, "dispensationHistory", dispHistory);
        this.$set(this, "participants", participantsActiveRegos);
        this.$set(this, "options", options);
        this.LOADING(false);
      })
      .catch(() => {
        this.notify({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning",
          window,
        });
        this.LOADING(false);
      });
  },
  watch: {
    selectVal() {
      this.$set(this, "tableData", []);
      const currentParticipant = this.participants[this.selectVal];
      if (
        Array.isArray(this.dispensationHistory[this.selectVal]) &&
        this.dispensationHistory[this.selectVal].length
      )
        this.getDispensationHistory(this.dispensationHistory[this.selectVal]);
      if (!currentParticipant.profile || !currentParticipant.profile.dob) return null;
      this.checkU18 = isMinorConsistent(currentParticipant.profile.dob);
      this.dispensationRego = [];
    },
  },
  methods: {
    ...mapActions("views", ["notify"]),
    ...mapMutations("root", ["LOADING"]),
    async getActiveRegos(id) {
      if (!this.participants[id]) return;
      this.LOADING(true);
      const currentParticipant = this.participants[id];
      if (this.selectDisp === "18 Month Registration Window") {
        if (!currentParticipant.profile || !currentParticipant.profile.dob) return;
        const u18 = this.checkU18;
        const birthMonth = parseInt(currentParticipant.profile.dob.slice(5, 7));

        if (u18 && birthMonth >= 7 && birthMonth <= 12) {
          const dispensationEligible = currentParticipant.activeRegos.filter((r) => r.allow18MRego);
          this.dispensationRego = dispensationEligible;
        }
      }
      this.LOADING(false);
    },
    getDispensationForm() {
      this.getActiveRegos(this.selectVal);
      if (!this.dispensationRego.length) {
        const errMsg = !this.selectDisp
          ? "Please select a dispensation type."
          : `No active registrations eligible for ${this.selectDisp} dispensation.`;

        const select = document.querySelector("#dispSelector");
        if (!this.selectDisp) select.parentElement.classList.add("invalid");
        else select.parentElement.classList.remove("invalid");

        this.notify({
          msg: errMsg,
          route: this.$route.name,
          type: "warning",
          window,
        });
      } else {
        const query = {
          participantId: this.selectVal,
          availableRego: this.dispensationRego,
          checkU18: this.checkU18,
          dispType: this.selectDisp,
        };
        this.$router.push({ name: "adddispensations", query });
      }
    },
    async getDispensationHistory(dispData) {
      if (!dispData.length) return;
      this.LOADING(true);
      try {
        const result = dispData.map((entry) => {
          const { type, rule, season, date, dispensated, state } = entry;
          return {
            season,
            type,
            rule,
            date: date ? moment(date).format("D/MM/yyyy") : "To Be Actioned",
            dispensated:
              state && state === "pending" ? "Pending" : dispensated ? "Approved" : "Denied",
          };
        });
        this.$set(this, "tableData", result);
      } catch (error) {
        this.notify({
          msg: "Could not retrieve participant's dispensation history",
          route: this.$route.name,
          type: "warning",
          window,
        });
      }
      this.LOADING(false);
    },
  },
  computed: {
    dispOptions() {
      return typesOfDispensations.filter((type) => type.value === "18 Month Registration Window");
    },
  },

  data() {
    return {
      checkU18: undefined,
      dispensationHistory: [],
      dispensationRego: [],
      options: [],
      participants: {},
      selectDisp: "",
      selectVal: "",
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  overflow-x: auto;
  background-color: transparent;

  .col_1 {
    min-width: 40px;
  }

  .col_2 {
    min-width: 60px;
  }

  .col_3 {
    min-width: 100px;
  }

  .cell {
    font-size: 0.9em;
    white-space: nowrap;
    text-align: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      tr,
      th {
        background-color: transparent;
      }
    }

    tbody {
      tr {
        background-color: white;
      }
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}
</style>
